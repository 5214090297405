
.pump-control {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}
  
.chart-row {
  display: flex;
  justify-content: space-between; /* Space between the charts */
  flex-wrap: wrap; /* Wrap the charts to the next line if screen is too small */
  gap: 20px; /* Add gap between charts */
}

.chart-container {
  width: 48%;   /* Each chart takes almost half of the row */
  max-width: 100%;
}


/* For the track (the line) */
input[type="range"] {
  -webkit-appearance: none;  /* Override default styles */
  width: 100%;  /* Full width */
  height: 8px;  /* This is the height of the track */
  background: #ddd;  /* Track background color */
  border-radius: 5px;  /* Rounded corners */
  outline: none;
  opacity: 0.7;
  transition: opacity .15s ease-in-out;
}

/* Track appearance for WebKit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;  /* Track height */
  cursor: pointer;
  background: #4CAF50;  /* Track color */
  border-radius: 5px;
}

/* Track appearance for Firefox */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;  /* Track height */
  background: #4CAF50;  /* Track color */
  border-radius: 5px;
}

/* For the thumb (the slider handle) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;  /* Override default styles */
  appearance: none;
  width: 40px;  /* Thumb width */
  height: 40px;  /* Thumb height */
  border-radius: 50%;  /* Circular thumb */
  background: #fff;  /* Thumb color */
  border: 2px solid #4CAF50;  /* Thumb border */
  cursor: pointer;
  position: relative;
  top: -20px;  /* Position the thumb in the middle of the track */
  transform: translateY(8px);
}

/* Thumb appearance for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;  /* Thumb width */
  height: 20px;  /* Thumb height */
  border-radius: 50%;  /* Circular thumb */
  background: #fff;  /* Thumb color */
  border: 2px solid #4CAF50;  /* Thumb border */
  cursor: pointer;
  position: relative;
  top: -16px; /* Same as above */
  transform: translateY(8px);
}

/* Thumb hover effect */
input[type="range"]:hover {
  opacity: 1;  /* Increase opacity on hover */
}  