.Select5 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.options-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option {
  position: absolute;
  width: 360px; /* Button size */
  height: 360px; /* Button size */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.1s ease;
  transform-origin: center; /* Scale from the center */
}

.stop-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

.stop-button-overlay img {
  width: 200px; /* Adjust size as needed */
  height: 200px;
}
